.QAndA-card .duodecim {
  h3 {
    font-size: 1.1rem;
    font-weight: 700;
    margin-top: 1.5rem;
    margin-bottom: .5rem;
  }

  // Style references as subscripts
  a span.text {
    position: relative;
    top: -0.5em;
    font-size: 80%;
    // Hide references for now
    display: none;
  }

  table {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-left: 1px;
    margin-right: 1px;
    border-collapse: collapse;
    border-spacing: 0;

    caption {
      max-width: 75vw;
      margin-bottom: 1rem;
      font-weight: 700;
    }

    th {
      background-color: #005293;
      color: #fff;
      font-weight: 700;
      padding: 0.5rem;
      vertical-align: top;
      text-align: inherit;
      text-align: -webkit-match-parent;
    }

    td {
      padding: 15px;
      border: 1px solid #ccc;
      text-align: left;
      vertical-align: top;
      font-size: 0.875em;
    }

    tr:nth-child(odd) {
      background-color: #f5f5f5;
    }

    tr:nth-child(2n) {
      background-color: #fff;
    }
  }

  p + p {
    margin-top: 1rem;
  }

  li {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .table-container {
    overflow-x: auto;
    max-width: 75vw;
  }
}
